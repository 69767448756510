<template>
  <div>
  
  </div>
</template>
<script>

export default {
    name: "LoginView",

  created() {
    window.location.href = "https://online.cplintasindo.co.id/";
  },

};
</script>