<template>
 
   
    <v-fade-transition mode="out-in">
      <v-row>
        <v-col cols="12">
          <v-card>
            <IframeComponent :url="iframeUrl" />
           
          </v-card>
        </v-col>

   

   
  </v-row>
    </v-fade-transition>
  
</template>
<script>
import IframeComponent from '../components/IframeComponent.vue';
  export default {

    components :{
    IframeComponent
    },
    data: () => ({
     iframeUrl: 'https://online.cplintasindo.co.id/' // Ganti dengan URL iframe yang diinginkan
    }),
   
    methods :{
 
    }
  }
</script>
