<template>
  <div class="home">
    <section class="hero">
      <div class="hero-text container">
        <h4>Legalitas</h4>
        <hr />
        <h3>
         PT. Bathi Dua Putra merupakan perusahaan yang fokus pada bidang pengiriman barang dalam negeri untuk melayani pelanggan di Indonesia. Dengan didukung Sumber Daya Manusia yang memiliki keahlian dan pengalaman, Kami yakin dapat memberikan pelayanan yang terbaik serta terus menerus melakukan inovasi dan beradaptasi dengan perkembangan global. BDP hadir untuk memberikan layanan pengiriman yang terbaik dengan komitmen, tepat dan handal.
        </h3>
        <hr />
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "AboutView",
  components: {},
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("../assets/law.jpg");
  background-attachment: fixed;
  position: relative;
  height: 100vh;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;
      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }
    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
}
</style>
