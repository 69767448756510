<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <!--  -->
        <v-list nav dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
              

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      
    </v-navigation-drawer>

    
    <v-app-bar app color="white">
      <v-app-bar-nav-icon v-if="!isFullPage" @click="toggleDrawer"></v-app-bar-nav-icon>

      <img src="./assets/logocpl.jpeg" style="max-width:200px;">
      <template v-if="isFullPage">
                <v-spacer></v-spacer>
                <v-btn text :key="item.title" v-for="item in items" v-text="item.text" :to="item.to" link></v-btn>            
                </template>
    </v-app-bar>

    
    <v-main>
      <router-view />
    </v-main>
    <!-- <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>BDP Cargo</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
export default {
  components: {},
  data: () => ({
    drawer: false,
    drawerFull: true,
    isFullPage: false,
    selectedItem: 0,
    role: localStorage.getItem("role_id"),
    items: [
      { text: "Home", icon: "mdi mdi-view-dashboard", to: "/" },
      // { text: "Tentang Kami", icon: "mdi mdi-account-plus", to: "/about" },
      // {
      //   text: "Legalitas",
      //   icon: "mdi mdi-checkbox-marked-circle-outline",
      //   to: "/legalitas"
      // },
      { text: "Login", icon: "mdi mdi-cash-multiple", to: "/login" }
    ]
  }),
  methods: {
    async HandleLogout() {
      try {
        //localStorage.setItem("authenticated",false)
        localStorage.setItem("role_id", "");
        this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    handleResize() {
      if (window.innerWidth >= 768) {
        this.isFullPage = true;
        this.drawer = false;
        this.drawerFull = true;
      } else {
        this.isFullPage = false;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style>
/* Gaya CSS lainnya */

/* Menyembunyikan v-app-bar-nav-icon saat full page */
@media screen and (min-width: 768px) {
  .v-app-bar__nav-icon {
    display: none !important;
  }
}
</style>