<template>
  <div>
    <iframe :src="url" width="100%" height="450"></iframe>
  </div>
</template>

<script>
export default {
  name: 'IframeComponent',
  props: {
    url: {
      type: String,
      required: true
    }
  }
}
</script>
