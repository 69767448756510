import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home'
import AboutView from '../views/About'
import LoginView from '../views/Login'
import Legalitas from '../views/Legalitas'
import OnlineView from '../views/OnlineView'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  }
  ,
  {
    path: '/legalitas',
    name: 'legalitas',
    component: Legalitas
  }
  ,
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
  ,
  {
    path: '/online',
    name: 'online',
    component: OnlineView
  }
  
]

const router = new VueRouter({
  mode : 'history',
  routes
})





export default router



