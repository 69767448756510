<template>
  <div class="home">
    <section class="hero">
      <div class="hero-text container">
        <h4 style="color:black">Tentang Kami</h4>
        <hr />
        <h3>PT. Bathi Dua Putra merupakan perusahaan yang fokus pada bidang pengiriman barang dalam negeri untuk melayani pelanggan di Indonesia. Dengan didukung Sumber Daya Manusia yang memiliki keahlian dan pengalaman, Kami yakin dapat memberikan pelayanan yang terbaik serta terus menerus melakukan inovasi dan beradaptasi dengan perkembangan global. BDP hadir untuk memberikan layanan pengiriman yang terbaik dengan komitmen, tepat dan handal.</h3>
        <hr />
        <br />
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="success" rounded @click="Download">Unduh Company Profile</v-btn>
        </v-card-actions>
      </div>
    </section>
    <div>
      <a :href="whatsappLink" class="whatsapp-button">
        <i class="fab fa-whatsapp"></i> Hubungi Kami di WhatsApp
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "AboutView",
  whatsappLink:
    "https://api.whatsapp.com/send?phone=6289654367407&text=*Hai%20Admin%20Saya%20mau%20tanya%20tentang%20pengiriman%20barang%20nih%2C%20Saya%20dapat%20wa%20nya%20dari%20Web%20nya%20bdpcargo.co.id%20Terima%20kasih*",
  components: {},
  methods: {
    async Download() {
      const fileUrl = "https://bdpcargo.co.id/doc/Company_Profile_BDP.pdf";

      // Buka tautan dalam tab baru
      window.open(fileUrl, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("../assets/about.png");
  background-attachment: fixed;
  position: relative;
  height: 100vh;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #25d366;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;
      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }
    hr:nth-child(4) {
      height: 6px;
      background-color: black;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
 
}
 .whatsapp-button {
    background-color: #25d366; /* Warna latar belakang WhatsApp */
    color: #fff; /* Warna teks */
    display: inline-block;
    padding: 10px 20px;
    border-radius: 30px; /* Untuk membuat tombol berbentuk bulat */
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Efek bayangan */
  }

  .whatsapp-button i {
    margin-right: 10px; /* Jarak antara ikon dan teks */
  }
</style>
